<template>

    <div :class="{'w-full h-full px-3 py-4': mobile, 'h-full p-2 overflow-hidden': !mobile}">

        <div v-if="mobile" class="absolute top-0 left-0 w-full h-20 z-20" 
        :class="{'mt-28': isVisible || searchInput, '-mt-20': !isVisible && !searchInput}"
        style="transition: all .3s">
            <div class="h-full w-full flex flex-col justify-end items-center p-2" :class="'bg-module-'+mode">
                <input type="text" v-model="searchInput" :placeholder="$t('buscarZona')" class="w-full h-10 px-2 rounded-xl" :class="'bg-box-'+mode+' text-dfont-'+mode">
            </div>
        </div>
        
        <All model="Zones" :query="query" :immediate="true" :config="config" v-slot="{data, loading}">

            <div v-if="!loading" :class="{'pt-6': searchInput != '', 'h-full grid grid-cols-3 gap-3 overflow-hidden': !mobile, 'h-auto': mobile}" >

                <All model="Comercialsdetail" :immediate="true" :query="query" :config="config" v-slot="{ data: detail, loading: loading2 }" :class="{'col-span-2 overflow-hidden': !mobile}">

                    <div v-if="!loading2 && !searchInput && searchInput == ''" class="h-auto">
                        
                        <div :class="{'h-auto w-full': mobile, 'h-full w-full': !mobile}">

                            <Intersect @enter="onEnter" @leave="onLeave" >

                                <div ref="target" class="h-full rounded-xl shadow_box flex flex-col p-2 mb-4" :class="'bg-module-'+mode">

                                    <div class=" flex flex-col justify-center items-center mt-2 relative" :class="{'h-40': mobile, 'h-72 flex-none': !mobile}">

                                        <donutchart :data="detail" />
                                        <div class="h-32 w-32 rounded-full absolute flex flex-col justify-center items-center">
                                            <span class="text-sm text-gray">{{ $t('total') }}</span>
                                            <span class="font-bold" :class="'text-dfont-'+mode">
                                                {{ getTotalDimension(detail) | reduceBigNumbers() }}
                                                <span v-if="dimensionName === 'Metros cuadrados'">€/m<sup>2</sup></span>
                                                <span v-else v-html="unit"></span>
                                            </span>
                                        </div>

                                    </div>

                                    <div class="overflow-hidden mx-2" :class="{'h-full': !mobile, 'h-28': mobile}">

                                        <div class="h-full overflow-auto">

                                            <div class="py-1 flex items-center mb-1" v-for="(country, index) in detail" :key="index" :class="{'h-10': !mobile, 'h-6': mobile}">
                                                
                                                <div class="h-full w-3/6 flex flex-row justify-start items-center">
                                                    <div class="h-full w-1 rounded-lg mr-2" :style="'background-color: '+ colors[(index<4) ? index: 4]"></div>
                                                    <span class="text-xs" :class="'text-font_gray-'+mode">{{ country.Name }}</span>
                                                </div>

                                                <div class="h-full w-2/6 flex flex-row justify-end items-center">
                                                    <span class="text-sm font-medium" :class="'text-dfont-'+mode">
                                                        {{ country.Dimension | reduceBigNumbers() }}
                                                        <span v-if="dimensionName === 'Metros cuadrados'">€/m<sup>2</sup></span>
                                                        <span v-else v-html="unit"></span>
                                                    </span>
                                                </div>

                                                <div class="h-full w-1/6 flex flex-row justify-end items-center">
                                                    <span class="text-sm font-medium" :class="'text-dfont-'+mode">{{ country.Percent | reduceBigNumbers() }}%</span>
                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </div>
                            
                            </Intersect>

                        </div>

                    </div>

                    <div v-else-if="!searchInput && searchInput == ''" class="h-40 rounded-xl shadow_box flex flex-col p-2 mb-4 relative" :class="'bg-module-'+mode">
                        
                        <loader :loading="loading2" />
                    
                    </div>

                </All>

                <div v-if="filteredComercials(data) && filteredComercials(data).length > 0" :class="{'overflow-auto h-full': !mobile, 'h-auto mt-8': mobile}">
                    
                    <zone v-for="(el,key) in filteredComercials(data)"
                    :data="el"
                    :key="key"
                    :rol="rol"
                    :dimensionName="dimensionName"
                    @openZone="openZone" />
                
                </div>

                <div v-else class="h-32 flex mt-6 flex-col justify-center items-center">
                    <span :class="'text-font_gray-'+mode">{{ $t('noData') }}</span>
                </div>

            </div>

            <div v-else class="h-40 relative">
                <loader :loading="loading" />
            </div>

        </All>

    </div>
</template>

<script>
    import { state, actions } from '@/store';
    import solidgauge from '@/components/solidgauge.vue';
    import donutchart from '@/components/donutchart.vue';
    import { All } from '@/api/components';
    import loader from '@/components/loader.vue';
    import doubleChart from '@/components/doubleChart.vue';
    import comercial from '@/components/comercial.vue';
    import zone from '@/components/zone.vue';
    import Intersect from 'vue-intersect';

    export default {
        components: {
            solidgauge,
            donutchart,
            All,
            loader,
            doubleChart,
            comercial,
            zone,
            Intersect
        },
        data() {
            return {
                isVisible: false,
                facturation: false,
                yearact: this.$moment().format('YYYY'),
                yearant: this.$moment().subtract(1, 'years').format('YYYY'),
                yearantant: this.$moment().subtract(2, 'years').format('YYYY'),
                config:{},
                //nameCompany: null,
                yesterday: this.$moment().subtract(1, 'days').format("DD MMM 'YY"),
                month: this.$moment().format("MMM 'YY"),
                montprevyear: this.$moment().subtract(1, 'years').format("MMM 'YY"),
                saletype: null,
                searchInput:'',
                countries:[],
                colors: ['#F48C40', '#69D4ED', '#B077E2', '#FBD967', '#AFA9A9']
            }
        },
        methods: {
            onLeave(){
                this.isVisible = true
            },
            onEnter(){
                this.isVisible = false
            },
            translateIndicator(word) {
                switch (word) {
                    case 'Precio medio':
                        return this.$t("averagePrice")
                        break;
                    case 'Metros cuadrados':
                        return this.$t("squareMeters")
                        break;
                    case 'Facturación':
                        return this.$t("billing")
                        break;
                    case 'Margen':
                        return this.$t("margin")
                        break;
                }
            },
            translate(word){ 
                return this.$t(word) 
            },
            toTops(){ 
                this.$router.push('grouptops') 
            },
            activeFacturation(){ 
                this.facturation = !this.facturation 
            },
            openComercial(comercial) {
                if (this.rol === 'Propiedad' || this.rol === 'Gerente' || comercial.Id === this.user.id) {
                    actions.setComercialSelected(comercial)
                    actions.setSaleTypeSelected(this.config.data.SaleType)
                    actions.setUserId(comercial.Id)
                    if (comercial.Id) {
                        actions.setIdZone(comercial.Id)
                        actions.setFilterZoneComercial(comercial.ZonesId[0].toLowerCase())
                        actions.setFilterZoneComercialName(comercial.ZonesName.split(',')[0])
                    }
                    else {
                        actions.setIdZone(comercial.ZonesId[0])
                        actions.setFilterZoneComercial(comercial.ZonesId[0].toLowerCase())
                        actions.setFilterZoneComercialName(comercial.ZonesName.split(',')[0])
                    }
                    this.$router.push({name: 'comercial', params:{comercial:state.idZone}})
                }
                else { }
            },
            openZone(zone) {
                actions.setSaleTypeSelected(state.saleTypeSelected.toUpperCase())
                actions.setZone(zone)
                actions.setIdZone(zone.Id.toUpperCase())
                actions.setFilterZoneComercial(zone.Id.toUpperCase())
                actions.setFilterZoneComercialName(zone.Name)
                actions.setComercialSelected(null)
                this.$router.push({name: 'zone', params:{idzone:zone.Id}})
            },
            toFilters(){ 
                this.$router.push({name : 'otherfilters'}) 
            },
            filteredComercials(data) {
                return Object.values(data).filter(post => {
                    return post['Name'].toLowerCase().includes(this.searchInput.toLowerCase())
                })
            },
            getTotalDimension(data) {
                var total = 0
                for (let index = 0; index < data.length; index++) { total += data[index].Dimension }
                if (this.dimensionName === 'Precio medio' || this.dimensionName === 'Margen') {
                    return (total / data.length)
                }
                else { return total }
            },
            dimensionFilters(){ 
                this.$router.push({ name: 'indicatorfilterszones' }) 
            }
        },
        computed: {
            monthName(){
                return this.$moment({M: state.month -1 }).format('MMMM')
            },
            user(){ 
                return state.user 
            },
            zone(){ 
                return state.zoneSelected 
            },
            comercialSelected() {
                if (state.idZone) { return state.idZone }
            },
            rol() { return state.user.rol.Name },
            nameCompany() { return state.companySelected },
            dimensionGroup() { return state.dimensionGroup },
            dimensionName() { return state.dimensionName },
            period() {
                switch(state.period) {
                    case 'year':
                        return this.$t("annual")
                        break;
                    case 'quarter':
                        return this.$t("quarterly")
                        break;
                    default:
                        return this.$t("monthly")
                }
                return state.period
            },
            unit() {
                let unit = '€'
                if (state.dimensionGroup == 1) { unit = 'm<sup>2</sup>' }
                if (state.dimensionGroup == 3) { unit = '%' }
                return unit
            },
            query() {
                return {
                    period: state.period,
                    IndustrialGroup: state.industrialGroup,
                    month: state.month
                }
            },
            comercial() { return state.comercialSelected },
            modelInfo() {
                if (state.comercialSelected) { return }
            },
            activeperiod() {
                switch (state.period) {
                    case 'month':
                        return this.$moment().format("MMM 'YY")
                        break;
                    case 'quarter':
                        return 'trim. ' + this.$moment().format("Q 'YY")
                        break;
                    case 'year':
                        return this.$moment().format("YYYY")
                        break;
                }
            },
            prevPeriod() {
                switch (state.period) {
                    case 'month':
                        return this.$moment().subtract(1, 'years').format("MMM 'YY")
                        break;
                    case 'quarter':
                        return 'trim. ' + this.$moment().subtract(1, 'years').format("Q 'YY")
                        break;
                    case 'year':
                        return this.$moment().subtract(1, 'years').format("YYYY")
                        break;
                }
            },
            mode(){
                return state.mode
            },
            mobile(){
                return state.isMobile
            }
        },
        mounted() {
            if (this.$route.params.saletype) {
                this.config = {
                    data: {
                        Company: state.idCompany,
                        Dimension: state.dimensionGroup,
                        SaleType: this.$route.params.saletype
                    }
                }
            }
            else {
                this.config = {
                    data: {
                        Company: state.idCompany,
                        Dimension: state.dimensionGroup,
                        SaleType: state.saleTypeSelected
                    }
                }
            }
        }
    }

</script>

<style>
.listFade { position:relative }
.listFade::after {
    content: '';
    width: 100%;
    height: 2rem;
    position: absolute;
    left: 0;
    bottom: 0;
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    z-index:100;
}
</style>
