<template>

    <!-- <div class="h-auto w-full rounded-xl relative" :class="{'shadow-pamesa cursor-pointer': selected || lastpage}" > -->
    <div class="h-auto w-full rounded-xl relative cursor-pointer">
        
        <div class="h-auto w-full mb-4 rounded-xl flex flex-col overflow-hidden relative shadow_module" :class="'bg-module-'+mode" @click="openZone(data)">
            
            <div v-if="!selected && !lastpage" class="absolute top-0 left-0 h-full w-full bg-opacity-50" :class="'bg-module-'+mode"></div>

            <div class="h-12 flex flex-row justify-start items-center px-4" :class="'bg-box-'+mode">

                <div class="h-8 w-8 mr-2 rounded-full flex flex-col justify-center items-center" :class="'bg-darkgreen-'+mode">
                    <span class="text-white text-lg font-bold">{{ data.Name.substr(0,1) }}</span>
                </div>

                <div class="h-full flex flex-1 min-w-0 flex-col justify-center items-start leading-none">

                    <span class="text-lg font-bold capitalize" :class="'text-dfont-'+mode">{{ $t('zone') }} {{ data.Name }}</span>

                    <span v-if="zonetype === '24C8C714394211EA966B005056AEAA71'" class="text-sm font-medium capitalize" :class="'text-dfont-'+mode">{{ $t('national') }}
                    </span>

                    <span v-if="zonetype === '24C85D60394211EA966B005056AEAA71'" class="text-sm font-medium capitalize" :class="'text-dfont-'+mode">{{$t('export')}}
                    </span>

                </div>
            

                <i class="mdi mdi-arrow-right text-2xl text-red ml-auto"></i>
                
            </div>

            <div class="h-auto w-full px-2 py-2">

                <div v-if="dimensionName === 'Facturación' || dimensionName === 'Metros cuadrados'"
                    class="h-auto w-full flex flex-row justify-between items-center px-2 mb-1">
                    <span class="text-xs" :class="'text-font_gray-'+mode">{{ $t('day') }}</span>
                    <span class="text-sm font-medium" :class="'text-dfont-'+mode">
                        {{ data.Yesterday | reduceBigNumbers() }} <span v-html="unit"></span>
                    </span>
                </div>

                <div class="h-auto w-full flex flex-row justify-between items-center px-2 mb-1">
                    <span v-if="dimensionName === 'Facturación' || dimensionName === 'Metros cuadrados'"
                        class="text-xs" :class="'text-font_gray-'+mode">{{$t('accumulated')}}
                    </span>
                    <span v-if="dimensionName === 'Precio medio'" class="text-xs" :class="'text-font_gray-'+mode">{{ $t('averagePrice') }}</span>
                    <span v-if="dimensionName === 'Margen'" class="text-xs" :class="'text-font_gray-'+mode">
                        {{ $t('margin') }}
                    </span>
                    <span class="text-sm font-medium" :class="'text-dfont-'+mode">{{data.Month | reduceBigNumbers()}} <span v-html="unit3"></span></span>
                </div>

                <div v-if="dimensionName === 'Facturación' || dimensionName === 'Metros cuadrados'"
                    class="h-auto w-full flex flex-row justify-between items-center px-2 mb-1">
                    <span class="text-xs" :class="'text-font_gray-'+mode">{{ $t('deliveryNotes') }}</span>
                    <span class="text-sm font-medium" :class="'text-dfont-'+mode">{{ data.DeliveryNote | reduceBigNumbers() }} <span v-html="unit"></span></span>
                </div>

                <div v-if="dimensionName === 'Facturación' || dimensionName === 'Metros cuadrados'"
                    class="h-auto w-full flex flex-row justify-between items-center px-2 mb-1">
                    <span class="text-xs" :class="'text-font_gray-'+mode">{{ $t('scope') }}</span>
                    <span class="text-sm font-medium" :class="'text-dfont-'+mode">{{ data.MonthPreview | reduceBigNumbers() }}<span v-html="unit"></span></span>
                </div>

                <div class="h-auto w-full flex flex-row justify-between items-center px-2 mb-1">
                    <span class="text-xs" :class="'text-font_gray-'+mode">Real {{ yearant }}</span>
                    <span class="text-sm font-medium" :class="'text-dfont-'+mode">{{ data.PastYear | reduceBigNumbers() }}<span v-html="unit3"></span></span>
                </div>

                <div class="h-auto w-full flex flex-row justify-between items-center px-2 mb-1">
                    <span class="text-xs" :class="'text-font_gray-'+mode">{{ $t('variation') }} {{ periodo }}</span>
                    <span class="text-sm font-medium" :class="'text-dfont-'+mode"><span v-if="data.MonthVariation >= 0">+</span>
                        {{ data.MonthVariation | reduceBigNumbers(2) }} <span v-html="unit2"></span>
                    </span>
                </div>

            </div>

            <div v-if="permisions" class="h-auto">
                <Request model="Companytocomercialmessage" action="create" :form="form" @success="onSuccess" @error="onError"
                    v-slot="{ request, loading: loadingrequest }" class="w-full">
                    <div v-if="!loadingrequest && data.Id" class="h-auto">
                        <div class="h-10 flex flex-row justify-start items-center px-4">
                            <div class="h-8 flex-1 pr-2" @keypress.enter="request">
                                <input ref="input" type="text" v-model="message" :placeholder="$t('writeMessage')"
                                    class="w-full h-full bg-lightgray rounded-lg px-2 shadow-inner" :class="'text-dfont-'+mode+' bg-box-'+mode">
                            </div>
                            <div @click="request" class="h-8 w-10 rounded-lg bg-red flex flex-row justify-center items-center">
                                <i class="mdi mdi-send text-white"></i>
                            </div>
                        </div>
                    </div>
                </Request>
            </div>

        </div>

    </div>

    <!-- <div class="h-auto rounded-lg flex flex-col p-2 mb-4 bg-white" :class="{'shadow_box cursor-pointer': selected || lastpage, 'bg-module-darkmode': mode == 'darkmode', 'bg-module-whitemode': mode == 'whitemode'}">
        <div class="h-16 flex flex-row"  @click="openZone(data)">
            <div class="h-full w-2/12 flex justify-center items-center">
                <div class="h-12 w-12 rounded-full overflow-hidden">
                    <vue-initials-img :name="data.Name" class="h-12 w-12"/>
                </div>
            </div>
            <div class="h-full w-9/12 flex flex-col justify-center items-start pl-2 truncate">
                <span class="font-semibold" :class="'text-dfont-'+mode">{{ $t('zone') }} {{ data.Name || 'Zona Sin Nombre' }}</span>
                <span v-if="zonetype === '24C8C714394211EA966B005056AEAA71'" class="text-sm font-semibold"
                      :class="{'text-purple': zonetype === '24C8C714394211EA966B005056AEAA71',
                    'text-blue': zonetype === '24C85D60394211EA966B005056AEAA71'}">{{ $t('national') }}
                </span>
                <span v-if="zonetype === '24C85D60394211EA966B005056AEAA71'" class="text-sm font-semibold"
                      :class="{'text-purple': zonetype === '24C8C714394211EA966B005056AEAA71',
                    'text-blue': zonetype === '24C85D60394211EA966B005056AEAA71'}">{{$t('export')}}
                </span>
            </div>
            <div class="h-full w-1/12 flex flex-row justify-center items-center">
                <i class="mdi mdi-arrow-right font-semibold" :class="'text-dfont-'+mode"></i>
            </div>
        </div>
        <div class="flex-1 flex flex-col justify-between pb-2" @click="openZone(data)">
            <All model="Groupdimensions" :query="query" :immediate="true" v-slot="{data: data4, loading: loading4}">
                <div v-if="!loading4" class="h-auto mb-2">
                <double-chart :current="data.PreviewPercent" :prev="data.PastYearPercent"
                    v-if="data4[dimension].Name !== 'Margen' && data4[dimension].Name !== 'Precio medio' && !loading4" />
                </div>
            </All>
            <div v-if="dimensionName === 'Facturación' || dimensionName === 'Metros cuadrados'"
                 class="h-auto w-full flex flex-row justify-between items-center px-2 mb-2">
                <span class="text-sm text-darkgray">{{ $t('day') }}</span>
                <span class="text-sm font-bold" :class="'text-dfont-'+mode">
                    {{ data.Yesterday | reduceBigNumbers() }} <span v-html="unit"></span>
                </span>
            </div>
            <div class="h-auto w-full flex flex-row justify-between items-center px-2 mb-2">
                <span v-if="dimensionName === 'Facturación' || dimensionName === 'Metros cuadrados'"
                    class="text-sm text-darkgray">{{$t('accumulated')}}
                </span>
                <span v-if="dimensionName === 'Precio medio'" class="text-sm text-darkgray">{{ $t('averagePrice') }}</span>
                <span v-if="dimensionName === 'Margen'" class="text-sm text-darkgray">
                    {{ $t('margin') }}
                </span>
                <span class="text-sm font-bold" :class="'text-dfont-'+mode">{{data.Month | reduceBigNumbers()}} <span v-html="unit3"></span></span>
            </div>
            <div v-if="dimensionName === 'Facturación' || dimensionName === 'Metros cuadrados'"
                class="h-auto w-full flex flex-row justify-between items-center px-2 mb-2">
                <span class="text-sm text-darkgray">{{ $t('deliveryNotes') }}</span>
                <span class="text-sm font-bold" :class="'text-dfont-'+mode">{{ data.DeliveryNote | reduceBigNumbers() }}<span v-html="unit"></span></span>
            </div>
            <div v-if="dimensionName === 'Facturación' || dimensionName === 'Metros cuadrados'"
                class="h-auto w-full flex flex-row justify-between items-center px-2 mb-2">
                <span class="text-sm text-darkgray">{{ $t('scope') }}</span>
                <span class="text-sm font-bold" :class="'text-dfont-'+mode">{{ data.MonthPreview | reduceBigNumbers() }}<span v-html="unit"></span></span>
            </div>
            <div class="h-auto w-full flex flex-row justify-between items-center px-2 mb-2">
                <span class="text-sm text-darkgray">Real {{ yearant }}</span>
                <span class="text-sm font-bold" :class="'text-dfont-'+mode">{{ data.PastYear | reduceBigNumbers() }}<span v-html="unit3"></span></span>
            </div>
            <div class="h-auto w-full flex flex-row justify-between items-center px-2 mb-2">
                <span class="text-sm text-darkgray">{{ $t('variation') }} {{ periodo }}</span>
                <span class="text-sm font-bold" :class="'text-dfont-'+mode"><span v-if="data.MonthVariation >= 0">+</span>
                    {{ data.MonthVariation | reduceBigNumbers(2) }} <span v-html="unit2"></span>
                </span>
            </div>
        </div>
        <div v-if="permisions" class="h-auto">
            <Request model="Companytocomercialmessage" action="create" :form="form" @success="onSuccess" @error="onError"
                v-slot="{ request, loading: loadingrequest }" class="w-full">
                <div v-if="!loadingrequest && data.Id" class="h-auto">
                    <div class="h-10 flex flex-row justify-start items-center px-4">
                        <div class="h-8 flex-1 pr-2" @keypress.enter="request">
                            <input ref="input" type="text" v-model="message" :placeholder="$t('writeMessage')"
                                class="w-full h-full bg-lightgray rounded-lg px-2 shadow-inner" :class="'text-dfont-'+mode+' bg-box-'+mode">
                        </div>
                        <div @click="request" class="h-8 w-10 rounded-lg bg-red flex flex-row justify-center items-center">
                            <i class="mdi mdi-send text-white"></i>
                        </div>
                    </div>
                </div>
            </Request>
        </div>
    </div> -->

</template>

<script>
import { state } from '@/store';
import { All, Request } from '@/api/components';
import doubleChart from '@/components/doubleChart.vue';

export default {
    props:['data', 'comercialSelected', 'key2', 'dimensionName', 'rol', 'lastpage', 'zonetype'],
    components: {
        All,
        Request,
        doubleChart
    },
    data() {
        return {
            yearant: this.$moment().subtract(1, 'years').format('YYYY'),
            message: null
        }
    },
    methods: {
        openZone(data) {
            if (this.lastpage) { this.$emit('openZone', data,this.zonetype) }
            else {
                if (this.rol === 'Propiedad' || this.rol === 'Gerente') { this.$emit('openZone', data) }
                else
                    if (this.rol === 'Comercial') {
                        if(this.selected){ this.$emit('openZone', data) }
                    }
                    else {}
            }
        },
        onSuccess() {
            this.message = null
            this.$message({
            message: this.$t("messageSentSuccessfully"),
            type: 'success'
            });
        },
        onError(error) { this.$message.error(this.$t("theMessageCouldNotBeSent")) }
    },
    computed: {
        unit() {
            let unit = '€'
            if (state.dimensionGroup === 1) { unit = 'm<sup>2</sup>' }
            if (state.dimensionGroup === 3) { unit = '%' }
            return unit
        },
        unit3() {
            let unit = '€'
            if (state.dimensionGroup === 1) { unit = ' €/m<sup>2</sup>' }
            if (state.dimensionGroup === 3) { unit = '' }
            return unit
        },
        unit2() {
            let unit = '%'
            if (state.dimensionGroup === 1) { unit = ' €/m<sup>2</sup>' }
            if (state.dimensionGroup === 3) { unit = '' }
            return unit
        },
        user() { return state.user },
        selected() {
            if (state.user.rol.Name === 'Propiedad' || state.user.rol.Name === 'Gerente') { return true }
            else
                if (state.user.rol.Name === 'Comercial') {
                    let prueba = null
                    for (let index = 0; index < state.user.relation.length; index++) {
                        if (state.user.relation[index].zonelink.IdZone.toUpperCase() === this.data.Id.toUpperCase()
                            && state.saleTypeSelected.toUpperCase() === state.user.relation[index].IdSaleType.toUpperCase() ) {
                            prueba = true
                        }
                    }
                    if (prueba === true) { return true }
                    else { return false }
                }
                else {}
        },
        form() {
            return {
                message: this.message,
                Company: state.idCompany,
                SaleType: state.saleTypeSelected,
                // IdUser: this.data.Id
                Zone: this.data.Id
            }
        },
        query() {
            return {
                period: state.period,
                IndustrialGroup: state.industrialGroup,
                month: state.month
            }
        },
        permisions() { return state.user.rol.Name === 'Gerente' },
        dimension() { return state.dimensionGroup },
        saletipes() { return state.saleTypeSelected },
        mode(){
            return state.mode
        }
    }
}
</script>